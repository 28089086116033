
import { Button, Form } from 'antd';
import React, { useContext, useState } from 'react';
import { createPortal } from 'react-dom';
import SearchConditionGroupInput from './SearchConditionGroupInput';
import AdvanceSearchPreviewResult from './AdvanceSearchPreviewResult';
import { useLocalization } from '../../../util/useLocalization';
import { Locale } from '../../../../localization/LocalizationKeys';
import { AdvanceSearchContext } from './AdvancedSearchBuilder';

type SearchConditionBuilderProps = {
  nextStep: () => void;
};

const SearchConditionBuilder: React.FC<SearchConditionBuilderProps> = ({
  nextStep
}) => {
  // const form = Form.useFormInstance<SearchInput>();
  const { searchId } = useContext(AdvanceSearchContext);
  const { formatMessage } = useLocalization();
  const [showPreview, setShowPreview] = useState(false);
  const advanceSearchForm = Form.useFormInstance();

  // const searchState = useSearchContextBuilder();

  /*  const basicMode = () => (initial?.criterias.nodes ?? []).map((group: any) => (
    <Select.OptGroup key={`${group.code}-${group.entityId}`} label={group.heading}>
      {group.criterias.nodes.map(criteria => (
        <Select.Option key={criteria.id} value={criteria.id}>
          {criteria.heading}
        </Select.Option>
      ))}
    </Select.OptGroup>
  )); */

  return (
    <div className="search-condition-builder-container">
      {showPreview
        && createPortal(<AdvanceSearchPreviewResult
          visible={showPreview}
          closePreview={() => setShowPreview(false)}
        />, document.body)}
      <SearchConditionGroupInput
        inputPath={[]}
        valuePath={[]}
      >
        <div className="action-button-container">
          {/* <Button
          type="default"
           onClick={() => setShowPreview(true)}
           style={{ marginRight: '10px' }}
           >Preview Data</Button> */}
          <Button
            // style={{ marginRight: '5px' }}
            type='primary'
            onClick={() => {
              advanceSearchForm.validateFields().then(() => nextStep());
            }}
          >
            {searchId && searchId > 0 ? 'Edit Search' : formatMessage(Locale.Text.Save_search)}
          </Button>
          {/* <Button type='primary' onClick={onAdvanceSearch}> */}
          {/*  {formatMessage(Locale.Command.Search)} */}
          {/* </Button> */}
        </div>
      </SearchConditionGroupInput>

    </div>
  );
};

export default SearchConditionBuilder;
