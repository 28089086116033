import React, { useMemo, useRef, useState } from 'react';
import { Form } from 'antd';
import { gql, useMutation } from '@apollo/client';
import { EntitiesSearchQuickActionItem } from '../../../components/entitiesSearch/top/EntitiesSearchQuickActionItemType';
import { useLocalization } from '../../../util/useLocalization';
import { Locale } from '../../../../localization/LocalizationKeys';
import { StateArray } from '../../../util/StateArrayType';
import CreatePersonAddWorkspace, { CreatePersonAddWorkspaceRef } from '../../sitePerson/create/CreatePersonAddWorkspace';
import { AddWorkplaceMutationMutation, AddWorkplaceMutationMutationVariables } from '../../../../gql/typings';
import { PERSON_SITES_FRAGMENT } from '../PersonDetails/personSitesFragment';
import { useDetailsContext } from '../../../components/DetailsView/useDetailsContext';

type ReturnProps = {
  hidden: React.ReactNode;
  action: EntitiesSearchQuickActionItem;
  showModalState: StateArray<boolean>;
  loading: boolean;
};

export const useAddWorkplaceToPersonAction = (
  personId: number,
  countryCode: string|undefined,
): ReturnProps => {
  const localization = useLocalization();
  const showModalState = useState(false);
  const ref = useRef<CreatePersonAddWorkspaceRef>(null);
  const { broadcastShouldRefetchData } = useDetailsContext();

  const [
    addWorkplace,
    { loading },
  ] = useMutation<AddWorkplaceMutationMutation, AddWorkplaceMutationMutationVariables>(ADD_WORKPLACE_MUTATION);

  return useMemo(() => ({
    showModalState,
    loading,
    action: {
      key: 'add-work-place-to-person',
      label: localization.formatMessage(Locale.Command.Add_workplace),
      type: 'dashed',
      onClick: () => showModalState[1](true),
    },
    /**
     * For some reason I can't stop this from rendering, without breaking the form.
     * So this is always being rendered, even when not visible.
     */
    hidden: (
      <Form.Provider
        onFormFinish={(name, { forms }) => {
          if (name === 'workplace') addWorkplace({
            variables: {
              input: {
                ...forms?.workplace?.getFieldsValue(true) ?? {},
                personId,
              }
            }
          }).then(() => {
            showModalState[1](false);
            ref.current?.clear();
            broadcastShouldRefetchData();
          });
        }}
      >
        <CreatePersonAddWorkspace
          ref={ref}
          loading={loading}
          hidden
          visibleState={showModalState}
          countryCode={countryCode}
        />
      </Form.Provider>
    ),
  }), [
    addWorkplace,
    broadcastShouldRefetchData,
    countryCode,
    loading,
    localization,
    personId,
    showModalState,
  ]);
};

const ADD_WORKPLACE_MUTATION = gql`
  mutation AddWorkplaceMutation($input: AddWorkplaceInput!) {
    createSitePerson(input: $input) {
      dcr { id }
      sitePerson {
        id
        ...PersonSitesFragment
      }
    }
  }
  ${PERSON_SITES_FRAGMENT}
`;
