import React, { CSSProperties, useEffect, useMemo } from 'react';
import { Input, Switch } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
// eslint-disable-next-line import/no-extraneous-dependencies
import { InputElements } from './SearchConditionElement';
import { SearchFormItem, SearchInputKeyName } from './SearchFormItem';
import AdvanceDateRangePicker from '../../AdvanceDateRangePicker/AdvanceDateRangePicker';
import SearchConditionCustomInputBuilder from './SearchConditionCustomInputBuilder';
import { useLocalization } from '../../../util/useLocalization';
import { Locale } from '../../../../localization/LocalizationKeys';
import AdvanceCriteriaSelectInput from '../../AdvanceCriteriaInput/types/AdvanceCriteriaSelectInput';
import { AdvanceSearchInputCriteria } from '../entitiesSearchAdvance';
import { useSystemCountriesState } from '../../../util/useSystemCountriesState';

type SearchConditionElementInputProps = {
  inputPaths: SearchInputKeyName[];
  valuePaths: SearchInputKeyName[];
  inputConfigs: AdvanceSearchInputCriteria;
  inputType: InputElements;
};


const SearchConditionElementInput: React.FC<SearchConditionElementInputProps> = ({
  inputType,
  inputPaths,
  valuePaths,
  inputConfigs
}) => {
  const countryCodesState= useSystemCountriesState();
  const formInstance = useFormInstance();
  const { formatMessage } = useLocalization();
  const defaultProps = {
    size: 'small' as SizeType,
    style: {
      minWidth: '100px'
    }
  };
  const form = useFormInstance();

  useEffect(() => {
    if (inputType !== InputElements.CustomInput && inputConfigs) {
      const inputConfigurationPath = [...valuePaths, 'inputConfiguration'];
      formInstance.setFields(
        [
          {
            name: [...inputConfigurationPath, 'inputType'],
            value: inputConfigs.inputType
          },
          {
            name: [...inputConfigurationPath, 'inputHeading'],
            value: inputConfigs.isHeadingKey
              ? formatMessage({
                id: inputConfigs.heading!
              }) : inputConfigs.heading
          },
          {
            name: [...inputConfigurationPath, 'inputPlaceholder'],
            value: inputConfigs.service ? formatMessage(Locale.General.Select) : ''
          },
          {
            name: [...inputConfigurationPath, 'valueService'],
            value: inputConfigs.service
          },
          {
            name: [...inputConfigurationPath, 'inputHeadingMeta'],
            value: inputConfigs.inputHeadingMeta
          },
          {
            name: [...inputConfigurationPath, 'parentEntityType'],
            value: inputConfigs.parentEntityType
          }
          // need to add type here
        ]
      );
    }

  }, // eslint-disable-next-line react-hooks/exhaustive-deps
  [inputType]);

  const typeInput: Record<InputElements, React.FC<{ size?: SizeType; style?: CSSProperties }>> = {
    [InputElements.Text]: () => <>
      <SearchFormItem name={[...inputPaths, 'value']}>
        <Input {...defaultProps} />
      </SearchFormItem>
    </>,
    [InputElements.Switch]: () => <>
      <SearchFormItem name={[...inputPaths, 'value']}>
        <Switch />
      </SearchFormItem>
    </>,
    [InputElements.Select]: () => <>
      <AdvanceCriteriaSelectInput
        criteria={inputConfigs}
        form={form}
        countryCodes={countryCodesState[0]}
        inputPath={[...inputPaths, 'value']}
        valuePath={[...valuePaths, 'value']}
        {...defaultProps}
      />
    </>,
    [InputElements.Date]: () => <>
      <AdvanceDateRangePicker namePath={[...valuePaths, 'value']} size='small' />
    </>,
    [InputElements.CustomInput]: () => <>
      <SearchFormItem name={[...inputPaths, 'inputConfiguration']}>
        <SearchConditionCustomInputBuilder
          inputPaths={[...inputPaths, 'inputConfiguration']}
          valuePaths={[...valuePaths, 'inputConfiguration']}
        />
      </SearchFormItem>
    </>,
    [InputElements.UserInput]: () => <></>,
    [InputElements.PrimaryMagic]: () => <></>
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const Component = useMemo(() => typeInput[inputType], [inputType]);
  return (
    <div className='search-condition-element-input'>
      <Component />
    </div>
  );
};
export default SearchConditionElementInput;
