import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import { Button, Form, Input, message } from 'antd';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import advanceLogo from '../../img/png/advance-logo-white.png';
import Icon from '../components/Icon/Icon';
import { isAuthenticated } from './authenticate';
import LinkBadge from '../components/Badge/LinkBandge';
import LoginFooter from './LoginFooter';
import { Locale } from '../../localization/LocalizationKeys';


const PasswordResetPage: React.FC = () => {
  const navigate = useNavigate();
  const [submitted, setSubmitted] = useState(false);
  const [form] = Form.useForm();
  const intl = useIntl();

  useEffect(() => {
    if (isAuthenticated()) navigate('/', { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleResetPassword = async (value: any) => {
    const username = get(value, 'username', '');
    if (username) {
      const response = await fetch('/password/reset', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username }),
      });

      if (response.status === 200) {
        message.info(intl.formatMessage(Locale.Text.Password_reset_email_sent_successfully),
          5);
        setSubmitted(true);
      } else {
        message.error(intl.formatMessage(Locale.Text.Something_went_wrong),
          5);
      }
    } else {
      message.error(intl.formatMessage(Locale.Text.No_email_provided),
        5);
    }
  };

  return (
    <section id="reset-password-screen">
      <div className="login-container">
        <img alt="advance logo" className="advance-logo" src={advanceLogo} />
        <div className="login-card reset-password-login-card">
          {submitted ? (
            <div>
              <h3 id="email-sent-message">{intl.formatMessage(Locale.Text.email_has_been_sent)}</h3>
              <div className="login-field back-to-login">
                <LinkBadge
                  url="/login"
                  label={intl.formatMessage(Locale.Text.Back_to_login)}
                  style={{
                    marginTop: '50px',
                  }}
                />
              </div>

            </div>
          ) : (
            <div>
              <Form id="reset-password-form" onFinish={handleResetPassword} form={form}>
                <h3 id="reset-password-heading">
                  {intl.formatMessage(Locale.Command.Password_reset)}
                </h3>
                <p>{intl.formatMessage(Locale.Text.reset_password_here)}</p>
                <Form.Item name="username" className="login-field login-username">
                  <Input
                    size="large"
                    suffix={<Icon iconType="USER" />}
                    placeholder={intl.formatMessage(Locale.General.Username)}
                    autoFocus
                  />
                </Form.Item>
                <div className="login-field button-submit">
                  <Form.Item>
                    <Button
                      className="login-button"
                      type='primary'
                      style={{
                        width: '50%',
                        textAlign: 'center',
                      }}
                    >{intl.formatMessage(Locale.Command.Reset)}</Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          )}

        </div>
      </div>
      <LoginFooter />
    </section>);
};

export default PasswordResetPage;
