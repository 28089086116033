import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { EntitiesSearchQuickActionItem } from '../../../../components/entitiesSearch/top/EntitiesSearchQuickActionItemType';
import { StateArray } from '../../../../util/StateArrayType';
import AddPersonsToSiteModal from './AddPersonsToSiteModal';
import { useLocalization } from '../../../../util/useLocalization';
import { HcoSectionRightQueryQuery } from '../../../../../gql/typings';
import { Locale } from '../../../../../localization/LocalizationKeys';

type ReturnProps = {
  hidden: React.ReactNode;
  action: EntitiesSearchQuickActionItem;
  showModalState: StateArray<boolean>;
  loading: boolean;
};

export const useAddPersonToSiteAction = (
  siteId: number,
  countryCode: string|undefined,
): ReturnProps => {
  const localization = useLocalization();
  const showModalState = useState(false);
  const { data, loading } = useQuery<HcoSectionRightQueryQuery>(DATA_QUERY);

  return {
    showModalState,
    loading,
    action: {
      key: 'add-person-to-site',
      label: localization.formatMessage(Locale.Command.Add_Person),
      type: 'dashed',
      hide: data?.canICreate !== true,
      onClick: () => showModalState[1](true),
    },
    hidden: (
      <AddPersonsToSiteModal
        visible={showModalState[0]}
        setVisible={showModalState[1]}
        siteId={siteId}
        countryCode={countryCode}
      />
    ),
  };
};


const DATA_QUERY = gql`
  query HcoSectionRightQuery {
    canICreate(entityType: AFFILIATION)
  }
`;
