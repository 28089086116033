import React, { useEffect, useState } from 'react';
import { Input, message, Modal, Radio, Space } from 'antd';
import { gql, useMutation } from '@apollo/client';
import { useLocalization } from '../../util/useLocalization';
import { EntitiesSearchQuickActionItem } from '../../components/entitiesSearch/top/EntitiesSearchQuickActionItemType';
import Icon from '../../components/Icon/Icon';
import { Locale } from '../../../localization/LocalizationKeys';
import { SITE_AFFILIATION_DATA_QUERY } from '../site/components/SiteAffiliationContainer';
import { Optional } from '../../util/StateArrayType';
import {
  ActivateHcpAffiliationMutation, ActivateHcpAffiliationMutationVariables,
  DeleteReasonDcrEnum, EntityTypeEnum,
  removeHcpHcoRelationMutationMutation,
  removeHcpHcoRelationMutationMutationVariables
} from '../../../gql/typings';
import { useDetailsContext } from '../../components/DetailsView/useDetailsContext';
import { useIsAnyInactive } from '../../util/useIsAnyInactive';
import { useIsAnyActive } from '../../util/useIsAnyActive';
import { TableRowSelectionReturnProps } from '../../components/Table/useTableRowSelection';

type ReturnType = {
  action: EntitiesSearchQuickActionItem;
  hidden: React.ReactNode;
};

export const useRemoveAffiliationsFromSite = (
  siteId: number,
  rowSelection: TableRowSelectionReturnProps,
  isMaintainerApb: boolean,
  dataTotalCount: Optional<number>,
): ReturnType => {
  const localization = useLocalization();
  const { selected } = rowSelection.selection;

  const [renderDcrNotification, setDcrNotification] = useState<boolean>(false);
  const [comment, setComment] = useState<string>('');
  const [reason, setReason] = useState<DeleteReasonDcrEnum>(DeleteReasonDcrEnum.NOWORKHERE);
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const { broadcastShouldRefetchData } = useDetailsContext();
  const [hasSelectedInactivateRecord] = useIsAnyInactive(EntityTypeEnum.AFFILIATION, selected);
  const [hasSelectedActivateRecord] = useIsAnyActive(EntityTypeEnum.AFFILIATION, selected);

  const [
    removeHcpSiteRelation,
    { loading: isRemoving },
  ] = useMutation<removeHcpHcoRelationMutationMutation, removeHcpHcoRelationMutationMutationVariables>(REMOVE_HCP_AFFILIATION);

  const [
    activateHcpSiteRelation,
    { loading: isActivating },
  ] = useMutation<ActivateHcpAffiliationMutation, ActivateHcpAffiliationMutationVariables>(ACTIVATE_HCP_AFFILIATION);

  useEffect(() => {
    if (selected) {
      setReason(dataTotalCount === selected.length
        ? DeleteReasonDcrEnum.HCOISONPOPULATED
        : DeleteReasonDcrEnum.NOWORKHERE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);


  const maintainerSourceCheck = () => {
    if (isMaintainerApb) {
      setModalVisible(false);
      setDcrNotification(true);
    } else {
      setModalVisible(false);
      removeHcpSiteRelation({
        variables: {
          linkIds: selected,
          reason,
          comment
        },
        refetchQueries: ['SiteAffiliationScrollList'],
      }).then(() => message.success(localization.formatMessage(Locale.Text.Action_was_successful)));
    }
  };

  const submitDcr = () => {
    setModalVisible(false);
    setDcrNotification(false);
    removeHcpSiteRelation({
      variables: {
        linkIds: selected,
        reason,
        comment
      },
      refetchQueries: ['SiteAffiliationScrollList', 'PersonAffiliationsTableQuery'],
    }).then(() => {
      broadcastShouldRefetchData();
      rowSelection.clearState();
      return message.success(localization.formatMessage(Locale.Text.Action_was_successful));
    });
  };


  const onButtonClick = () => {
    if (isMaintainerApb) {
      setModalVisible(true);
      setDcrNotification(false);
    } else if (!hasSelectedInactivateRecord) {
      removeHcpSiteRelation({
        variables: {
          linkIds: selected,
          reason,
          comment
        },
        refetchQueries: [SITE_AFFILIATION_DATA_QUERY],
      }).then(() => {
        broadcastShouldRefetchData();
        rowSelection.clearState();
        return message.success(localization.formatMessage(Locale.Text.Action_was_successful));
      });
    } else if (hasSelectedInactivateRecord) {
      Promise.all(selected.map(linkId => activateHcpSiteRelation({
        variables: { linkId },
      }))).then(() => {
        broadcastShouldRefetchData();
        rowSelection.clearState();
        return message.success(localization.formatMessage(Locale.Text.Action_was_successful));
      });
    }
  };

  const getLabel = () => {
    const label = hasSelectedInactivateRecord
      ? localization.formatMessage(Locale.Command.Activate_record)
      : localization.formatMessage(Locale.Text.Dcr_remove_affiliation_persons);
    const tooltip = hasSelectedInactivateRecord
      ? undefined
      : localization.formatMessage(Locale.Text.Remove_affiliation_toltip);

    return isMaintainerApb ? <Icon
      style={{ height: '16px', marginRight: '5px' }}
      toolTip={label}
      iconType="DCR_WHITE"
      text={tooltip}
    /> : <span>{label}</span>;
  };

  return {
    action: {
      key: 'remove-links',
      hide: (hasSelectedInactivateRecord && hasSelectedActivateRecord) || selected.length === 0,
      onClick: onButtonClick,
      loading: isRemoving || isActivating,
      label: getLabel(),
      type: 'default',
      danger: true
    },
    hidden: (
      <>
        <Modal
          title={localization.formatMessage(Locale.Text.Dcr_remove_affiliation_persons)}
          open={modalVisible}
          onOk={maintainerSourceCheck}
          onCancel={() => setModalVisible(false)}
        >
          <p>{localization.formatMessage(Locale.Text.Dcr_remove_affiliation_persons_text)}</p>
          <div>

            <Radio.Group value={reason} onChange={e => setReason(e.target.value)}>
              <Space direction="vertical">
                <Radio value={DeleteReasonDcrEnum.OPTOUT}>
                  {localization.formatMessage(Locale.Text.Opt_out)}</Radio>
                <Radio value={DeleteReasonDcrEnum.DESCEASED}>
                  {localization.formatMessage(Locale.Text.Deceased)}</Radio>
                <Radio value={DeleteReasonDcrEnum.RETIRED}>
                  {localization.formatMessage(Locale.Text.Retired)}</Radio>
                <Radio value={DeleteReasonDcrEnum.WORKINGABROUD}>
                  {localization.formatMessage(Locale.Text.Working_abroad)}</Radio>
                <Radio value={DeleteReasonDcrEnum.NOWORKHERE}>
                  {localization.formatMessage(Locale.Text.No_work_here)}</Radio>
                <Radio value={DeleteReasonDcrEnum.TEMPNOWORKHERE}>
                  {localization.formatMessage(Locale.Text.Temp_no_work_here)}</Radio>
                <Radio value={DeleteReasonDcrEnum.HCOISCLOSEDDOWN}>
                  {localization.formatMessage(Locale.Text.HCO_is_closed_down)}
                </Radio>
                <Radio value={DeleteReasonDcrEnum.HCOISONPOPULATED}>
                  {localization.formatMessage(Locale.Text.HCO_is_unpopulated)}</Radio>
                <Radio value={DeleteReasonDcrEnum.OTHER}>
                  {localization.formatMessage(Locale.Text.Other)}
                  {reason === DeleteReasonDcrEnum.OTHER
                    ? <Input autoFocus onChange={(e) => setComment(e.target.value)} style={{ marginTop: 5 }} /> : null}
                </Radio>
              </Space>
            </Radio.Group>
          </div>
        </Modal>
        <Modal
          title="Please confirm"
          open={renderDcrNotification}
          onOk={submitDcr}
          onCancel={() => setDcrNotification(false)}
          okText="Yes"
          cancelText="No"
        >
          <div style={{ textAlign: 'center' }}>
            <p>{localization.formatMessage(Locale.Text.Dcr_remove_affiliation_dcr_confirm)}</p><br />
            <p>{localization.formatMessage(Locale.Text.Are_you_sure)}</p>
          </div>
        </Modal>
      </>
    ),
  };
};

const ACTIVATE_HCP_AFFILIATION = gql`
  mutation ActivateHcpAffiliation($linkId: Int!) {
    setSitePersonActiveStatus(id: $linkId, isActive: true) {
      id
      isActive
    }
  }
`;

const REMOVE_HCP_AFFILIATION = gql`
  mutation removeHcpHcoRelationMutation($linkIds: [Int!]!, $comment: String, $reason: DeleteReasonDcrEnum!) {
    removeHcpSiteRelation(linkIds: $linkIds, comment: $comment, reason: $reason)
  }
`;
