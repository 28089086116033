import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, notification, Space } from 'antd';
import Title from 'antd/es/typography/Title';
import EntitiesSearchActions from './EntitiesSearchActions';
import EntitiesSearchDrawer from './drawer/EntitiesSearchDrawer';
import EntitiesSearchContext from '../EntitiesSearchContext';
import Icon from '../../Icon/Icon';
import { Locale } from '../../../../localization/LocalizationKeys';
import SimpleSearchOverview from './drawer/overview/SimpleSearchOverview';
import { useLocalization } from '../../../util/useLocalization';
import EntitiesSearchTitleAndCountries from './EntitiesSearchTitleAndCountries';
import Puffin from '../../Puffin/Puffin';
import { AdvanceCriteriaInputFactory } from '../../AdvanceCriteriaInput/AdvanceCriteriaInputFactory';

const puffinNotificationKey = 'puffin-global-search-notification';

type EntitiesSearchAdvanceTopProps = {
  searchHeading: string;
};
const EntitiesSearchAdvanceTop: React.FC<EntitiesSearchAdvanceTopProps> = ({
  searchHeading,
  children = <></>,
}) => {
  const {
    selectedAdvanceCriterias,
    doSearch,
    form,
    setSelectedSaved,
    selectedCountriesState: [selectedCountries],
    globalState,
  } = useContext(EntitiesSearchContext);
  const [drawerState, setDrawerState] = useState<'hidden' | 'search-drawer' | 'save-search-drawer'>('hidden');
  const localization = useLocalization();
  const onSaveSearch = () => {
    setSelectedSaved(null);
    setDrawerState('save-search-drawer');
  };

  const [api, contextHolder] = notification.useNotification();


  const isGlobal = globalState && globalState[0];

  const removeNonSupportedGlobalSearchCriterias = () => {
    api.destroy(puffinNotificationKey);
  };

  const openNotification = () => {
    api.open({
      key: puffinNotificationKey,
      message: `${localization.formatMessage(Locale.General.Attention)}!`,
      duration: null,
      description: <Space direction="vertical">
        <Puffin
          loop
          type="explaining"
          message={localization.formatMessage(Locale.Text.Local_criterias_added_to_global_search)}
        />
        <Space align="center" style={{ width: '100%', justifyContent: 'center' }}>
          <Button onClick={() => api.destroy(puffinNotificationKey)}>
            {localization.formatMessage(Locale.Command.Cancel)}
          </Button>
          <Button type="primary" onClick={removeNonSupportedGlobalSearchCriterias}>
            {localization.formatMessage(Locale.Command.Remove)}
          </Button>
        </Space>
      </Space>,
    });
  };

  useEffect(() => {
    if (isGlobal) form.validateFields().catch(() => openNotification());
    else api.destroy(puffinNotificationKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, isGlobal]);

  return (
    <div className="entities-search-top-container">
      {contextHolder}
      <EntitiesSearchTitleAndCountries />
      <Title level={4} style={{ color: 'white' }}>{searchHeading}</Title>
      <Form layout="inline" size="large" form={form} onFinish={() => doSearch('EntitiesSearchAdvanceTop:Form:onFinish')}>
        {/* render inputs */}
        {
          selectedAdvanceCriterias.map(criteria => (
            <AdvanceCriteriaInputFactory
              key={criteria.code}
              criteria={criteria}
              form={form}
              countryCodes={selectedCountries}
            />
          ))
        }
        <Form.Item className="criteria-plus">
          <Space>
            <Button onClick={() => setDrawerState('search-drawer')} type='dashed'>
              Build Search
            </Button>
            <Button
              type="primary"
              onClick={() => doSearch('EntitiesSearchAdvanceTop:Button:onClick')}
              id="entities-search-advance-button"
            >
              <Icon
                iconType="SEARCH"
                style={{ height: 14, marginRight: 5 }}
                text={localization.formatMessage(Locale.Command.Search)}
              />
            </Button>

          </Space>

        </Form.Item>
        <Button htmlType="submit" style={{ display: 'none' }} />
        <EntitiesSearchDrawer
          drawerState={[
            drawerState === 'search-drawer' || drawerState === 'save-search-drawer',
            v => setDrawerState(v ? 'search-drawer' : 'hidden'),
          ]}
          drawerWidth={600}
        >
          {drawerState === 'save-search-drawer' && <SimpleSearchOverview
            drawerState={[true, v => setDrawerState(v ? 'search-drawer' : 'hidden')]}
          />}
        </EntitiesSearchDrawer>
      </Form>
      <EntitiesSearchActions isAdvanceSearch onSaveSearch={onSaveSearch}>
        {children}
      </EntitiesSearchActions>
    </div>
  );
};

export default EntitiesSearchAdvanceTop;
