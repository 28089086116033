import React, { forwardRef, useImperativeHandle, useMemo, useRef } from 'react';
import { Tabs } from 'antd';
import { Locale } from '../../../../../localization/LocalizationKeys';
import { EntitiesSearch, EntitiesSearchRef } from '../../../../components/entitiesSearch';
import { EntityTypeEnum } from '../../../../../gql/typings';
import CreatePerson, { CreatePersonRef } from '../../../person/create/CreatePerson';
import { StateArray } from '../../../../util/StateArrayType';
import { useLocalization } from '../../../../util/useLocalization';
import { TableRowSelectionReturnProps } from '../../../../components/Table/useTableRowSelection';
import { CreatePersonAddWorkspaceSiteStepRef } from '../../../sitePerson/create/steps/CreatePersonAddWorkspaceSiteStep';

type AddPersonsToSiteStep1Props = {
  siteId: number;
  countryCode: string|undefined;
  next: () => void;
  personTableSelection: TableRowSelectionReturnProps;
  tabState: StateArray<'existing-person'|'create-person'>;
};

export type AddPersonToSiteRef = {
  clear: () => void;
};

const AddPersonsToSiteStep1 = forwardRef<
AddPersonToSiteRef,
AddPersonsToSiteStep1Props>(({
  siteId,
  countryCode,
  next,
  personTableSelection,
  tabState: [currentTab, setCurrentTab]
}, ref) => {
  const localization = useLocalization();
  const entitiesSearchRef = useRef<EntitiesSearchRef>(null);
  const createPersonRef = useRef<CreatePersonRef>(null);

  const onCreateEntityTab = () => {
    setCurrentTab('create-person');
  };

  const initialValues = useMemo(() => {
    if (entitiesSearchRef.current && Object.keys(entitiesSearchRef.current.formValues).length > 0) return {
      ...entitiesSearchRef.current.formValues,
      countryCode,
    };
    return { countryCode };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entitiesSearchRef.current?.formValues, countryCode]);

  useImperativeHandle(ref, () => ({
    clear: () => {
      createPersonRef.current?.clear();
      entitiesSearchRef.current?.clearCriterias();
      entitiesSearchRef.current?.refetch();
    },
  }) as CreatePersonAddWorkspaceSiteStepRef, []);

  return (
    <Tabs
      activeKey={currentTab}
      // @ts-ignore
      onChange={setCurrentTab}
      items={[
        {
          key: 'existing-person',
          label: localization.formatMessage(Locale.Command.Select_person),
          children: (
            <EntitiesSearch
              globalSupport={false}
              ref={entitiesSearchRef}
              onCreateEntityTab={onCreateEntityTab}
              entityType={EntityTypeEnum.PERSON}
              tableSelection={personTableSelection}
              criteriaFilter={{ excludeSiteId: siteId }}
              quickActions={[{
                key: 'select',
                disabled: !personTableSelection.selection.selected[0],
                label: localization.formatMessage(Locale.Command.Select),
                type: 'primary',
                className: 'select-site-quick-action',
                onClick: next,
              }]}
            />
          ),
        },
        {
          key: 'create-person',
          label: localization.formatMessage(Locale.Command.Create_Person),
          children: <CreatePerson ref={createPersonRef} initialValues={initialValues} />,
        }
      ]}
    />
  );
});


export default AddPersonsToSiteStep1;
