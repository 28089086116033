import React, { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { Alert, Modal } from 'antd';
import { gql } from '@apollo/client/core';
import { useQuery } from '@apollo/client';
import { EntitiesSearch } from '../../../../components/entitiesSearch';
import { ActivityDetailContext } from '../ActivityDetailsTypes';
import HeaderWithValue from '../../../../components/DisplayFields/HeaderWithValue';
import { Locale } from '../../../../../localization/LocalizationKeys';
import { UseActivityPersonSelectionReturnTypeOld } from './useActivityPersonSelection';
import {
  AttachmentRuleEnum,
  EntityTypeEnum,
  FindHcoActivityAffiliationsQuery,
  FindHcoActivityAffiliationsQueryVariables
} from '../../../../../gql/typings';
import { useTableRowSelection } from '../../../../components/Table/useTableRowSelection';
import EntityTable from '../../../../components/entitiesSearch/results/EntityTable';
import { useLocalization } from '../../../../util/useLocalization';


const ActivitySinglePersonDetails: React.FC<{ use: UseActivityPersonSelectionReturnTypeOld }> = ({
  use: {
    personsToShow,
    replacePerson,
    removePersons,
    totalCount,
    loading
  }
}) => {
  const intl = useIntl();
  const {
    activityType,
    locations
  } = useContext(ActivityDetailContext);
  const rule = activityType?.personRule;
  const allowManyPersons = rule === AttachmentRuleEnum.OPTIONAL_MANY || rule === AttachmentRuleEnum.MANY;
  const morePersonThanRule = personsToShow && personsToShow.length > 1 && !allowManyPersons;
  const hasMultipleSelection = morePersonThanRule || allowManyPersons;
  const personTableSelection = useTableRowSelection({ type: hasMultipleSelection ? 'checkbox': 'radio' });
  const [showEntitiesSearch, setShowEntitiesSearch] = useState(false);
  const localization = useLocalization();
  const { activityId } = useContext(ActivityDetailContext);

  const {
    data
  } = useQuery<FindHcoActivityAffiliationsQuery, FindHcoActivityAffiliationsQueryVariables>(DATA_SITE_QUERY, {
    variables: {
      siteId: locations ? Number(locations?.[0]) : 0
    },
  });

  const allowRemove = !activityId
    || personsToShow.length > 1
    || rule === AttachmentRuleEnum.OPTIONAL_ONE
    || rule === AttachmentRuleEnum.OPTIONAL_MANY;
  const allowChange = !allowRemove && rule == AttachmentRuleEnum.ONE;

  return (
    <HeaderWithValue
      heading={intl.formatMessage(Locale.Attribute.Person_HCP)}
      className="activity-single-persons-details-container"
    >
      <div className='activity-single-persons-table-container'>
        {personsToShow && personsToShow.length > 1 && !allowManyPersons && (
          <Alert
            type="error"
            showIcon
            style={{ margin: '10px 0' }}
            message={intl.formatMessage(Locale.Text.You_are_not_allowed_to_have_more_than_one_type_to_activity, {
              count: personsToShow.length - 1,
              type: personsToShow.length === 2
                ? intl.formatMessage(Locale.Attribute.Person_HCP)
                : intl.formatMessage(Locale.General.Persons_HCP),
            })}
          />
        )}
        <EntityTable
          entityType={EntityTypeEnum.PERSON}
          tableSelection={personTableSelection}
          tableProps={{ className: 'activity-single-person-details-table-container' }}
          showCreateEntityButton={false}
          quickActions={[
            ...((allowChange || personsToShow.length === 0) ? [
              {
                key: 'open-add-person-modal',
                className: 'open-add-person-modal',
                label: personsToShow.length === 0
                  ? localization.formatMessage(Locale.Command.Add_Person)
                  : localization.formatMessage(Locale.Command.Change),
                loading,
                onClick: () => setShowEntitiesSearch(true),
              }
            ]: []),
            ...(allowRemove ? [{
              key: 'remove-persons',
              hide: personTableSelection.selection.count === 0,
              label: localization.formatMessage(Locale.Command.Remove_Persons),
              onClick: () => removePersons(personTableSelection.selection).finally(() => personTableSelection.clearState()),
              loading,
              confirm: !!activityId && {
                label: localization.formatMessage(Locale.Text.Are_you_sure_to_remove_persons_from_activity),
              },
            }]: [])
          ]}
          data={{
            loading,
            totalCount,
            nodes: personsToShow,
          }}
        />
      </div>

      <Modal
        open={showEntitiesSearch}
        onCancel={() => setShowEntitiesSearch(false)}
        footer={null}
        width="80%"
      >
        <EntitiesSearch
          entityType={EntityTypeEnum.PERSON}
          tableSelection={personTableSelection}
          defaultCriterias={[
            { criteriaCode: 'APB_SNAME', value: data ? data.site?.name : '', },
          ]}
          quickActions={[{
            key: 'select-person',
            disabled: personTableSelection.selection.count === 0,
            type: 'primary',
            onClick: () => {
              setShowEntitiesSearch(false);
              return replacePerson(personTableSelection.selection.selected[0]!);
            },
            label: intl.formatMessage(Locale.Text.Attach_person_to_activity),
          }]}
        />
      </Modal>
      {/* TODO: Remove this clearfix! */}
      <div className="clearfix" />
    </HeaderWithValue>
  );
};


export const DATA_SITE_QUERY = gql`
  query FindHcoActivityAffiliations($siteId: Int!) {
    site(siteId: $siteId){
      id
      name
    }
  }
`;


export default ActivitySinglePersonDetails;
