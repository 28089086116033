import { FormInstance } from 'antd';
import { SearchInputKeyName } from '../entitiesSearch/advanced/SearchFormItem';
import { SearchConditionOperationEnum } from '../../../gql/typings';


export type TabKey = 'moreThan'|'lessThan'|'between'|'exclude';

export type RangePickerInputProps = {
  disabled: boolean;
  inputPath: SearchInputKeyName[];
  valuePath: SearchInputKeyName[];
  name: [string, string];
};


export type RangePickerForm = {
  moreThan?: number;
  lessThan?: number;
  exclude?: number;
  betweenFrom?: number;
  betweenTo?: number;
};


export const DisplayStringConfig = {
  moreThan: 'More Than **',
  lessThan: 'Less Than **',
  between: 'Between ** and **',
  exclude: 'Exclude **'
};

export const operationMapping = {
  moreThan: SearchConditionOperationEnum.MORE_THAN,
  lessThan: SearchConditionOperationEnum.LESS_THAN,
  between: SearchConditionOperationEnum.BETWEEN,
  exclude: SearchConditionOperationEnum.NOT_EQUAL
};

export const keyToOperationMapping = {
  [SearchConditionOperationEnum.IN]: 'moreThan',
  [SearchConditionOperationEnum.EQUAL]: 'lessThan',
  [SearchConditionOperationEnum.BETWEEN]: 'between',
  [SearchConditionOperationEnum.NOT_EQUAL]: 'exclude',
};
export const isFormValueUndefined = (formValues: RangePickerForm, key: TabKey): boolean => {
  if (key === 'between') return formValues.betweenFrom === undefined || formValues.betweenTo === undefined;
  return formValues[key] === undefined;
};

// TODO: This display string should be way better!
export const generateDisplayString = (formValues: RangePickerForm | undefined, key: TabKey): string => {
  if (!formValues) return '';
  if (isFormValueUndefined(formValues, key)) return '';
  if (key === 'between') {
    return DisplayStringConfig[key]
      .replace('**', formValues!.betweenFrom!.toString())
      .replace('**', formValues!.betweenTo!.toString());
  }
  return DisplayStringConfig[key].replace('**', formValues![key]!.toString());
};

export const getInitialValue = (form: FormInstance, valuePath: SearchInputKeyName[], inputNamePath: [string, string]) => {
  const operation = form.getFieldValue([...valuePath, 'operation']) as keyof typeof keyToOperationMapping;
  const value = form.getFieldValue([...valuePath!, inputNamePath[1]]);
  if (!(operation && value)) return {};
  const isBetween = value.toString().includes('..');
  if (!isBetween) {
    const mappedOperation = keyToOperationMapping[operation];
    if (operation && value) {
      return { [mappedOperation]: value };
    }
  }
  const formattedValue = value.split('..');
  if (!formattedValue) return {};
  return {
    betweenFrom: formattedValue[0],
    betweenTo: formattedValue[1]
  };
};
