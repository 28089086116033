import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Form, Select, Input } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { get, isEqual } from 'lodash';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import * as UUID from 'uuid';
// eslint-disable-next-line import/no-extraneous-dependencies
import { NamePath, StoreValue } from 'rc-field-form/es/interface';
import { useColors } from '../../../util/useColor';
import { EntityTypeEnum, SearchConditionEnum } from '../../../../gql/typings';
import RangePicker from '../../RangePicker/RangePicker';
import { SearchFormItem, SearchInputKeyName } from './SearchFormItem';
import EntityCascader from '../../EntityCascader/EntityCascader';
import {
  defaultInputOptions,
  inputOptionsGenerator, operatorOptions,
  searchConditionOptions
} from './options';
import SearchConditionGroupInput from './SearchConditionGroupInput';
import SearchConditionElementInput from './SearchConditionElementInput';
import { AdvanceSearchContext } from './AdvancedSearchBuilder';
import { useLocalization } from '../../../util/useLocalization';
import { AdvanceSearchInputCriteria } from '../entitiesSearchAdvance';

type ConditionRendererProps = {
  inputPaths: SearchInputKeyName[];
  valuePath: SearchInputKeyName[];
  onChange: () => void;
  typeValue: string | undefined;
  code?: string;
};
type SearchConditionElement = {
  inputPaths: SearchInputKeyName[];
  valuePath: SearchInputKeyName[];
  isEntitySelect: boolean;
  removeElement: () => void;
};

type SearchConditionEnumWithoutNest = Exclude<SearchConditionEnum, SearchConditionEnum.NEST>;


export enum InputElements {
  Text = 'Text',
  Date = 'Date',
  MultiGroupSelect = 'Select',
  PrimaryMagic = 'PrimaryMagic',
  MultiSelect = 'Select',
  Select = 'Select',
  Switch = 'Switch',
  UserInput = 'UserInput',
  CustomInput = 'CustomInput'
}

const PARENT_ENTITY_TYPE = [EntityTypeEnum.PERSON, EntityTypeEnum.SITE, EntityTypeEnum.AFFILIATION, EntityTypeEnum.SELECTION];

const getAttributeData = (getter: (name: NamePath) => StoreValue, path: SearchInputKeyName[]) => {
  const pathsObj = getter(path);
  if (!pathsObj) return null;
  const toParseData = pathsObj[pathsObj.length - 1];
  if (typeof toParseData != 'string') return toParseData;
  return JSON.parse(toParseData);
};
const ConditionRenderer: React.FC<ConditionRendererProps> = ({
  inputPaths,
  valuePath,
  onChange,
  typeValue,
  code
}) => {
  const { entityType, searchId } = useContext(AdvanceSearchContext);
  const formInstance = useFormInstance();
  const { formatMessage } = useLocalization();
  if (searchId) {
    if (typeValue !== SearchConditionEnum.COUNT) {
      const cascaderValue = formInstance.getFieldValue([...valuePath]);
      const { inputHeadingMeta } = cascaderValue.inputConfiguration;
      const inputHeading = formatMessage({ id: cascaderValue.inputConfiguration.inputHeading });
      return (
        <div className="read-only-value">
          {inputHeadingMeta ? `${inputHeadingMeta}/${inputHeading}` : inputHeading}
        </div>
      );
    }
  }

  const EntityCascaderFormItem: React.FC<
  { showAttributes?: boolean;
    onChange: () => void;
    isCount?: boolean;
  }
  > = useCallback(({ showAttributes = true, onChange, isCount }) => (
    <div className="search-condition-element-property">
      <EntityCascader
        inputPath={[...inputPaths, 'paths']}
        showAttributes={showAttributes}
        code={code}
        onChangeFn={onChange}
        rules={[
          {
            required: true,
            message: 'Please input value'
          }
        ]}
        isCount={isCount}
      />
    </div>
  ), // eslint-disable-next-line react-hooks/exhaustive-deps
  []);


  const test: Record<SearchConditionEnumWithoutNest, React.FC<{ valuePath?: SearchInputKeyName[] }>> = useMemo(() => (
    {
      [SearchConditionEnum.COUNT]: () => {
        const varItems = () => {
          if (searchId) {
            const operationName = (formInstance.getFieldValue([...valuePath, 'operation']) || '').replace('_', ' ');
            const operationValue = formInstance.getFieldValue([...valuePath, 'value'])[0];
            const entityType = formInstance.getFieldValue([...valuePath, 'nest', 'entityType']);
            return (
              <>
                <div className="read-only-value">
                  {`${operationName} ${operationValue}`}
                </div>
                <div className="read-only-value">{entityType}</div>
              </>
            );
          }
          return (
            <>
              <RangePicker inputPath={[...inputPaths]} name={['operation', 'value']} valuePath={valuePath} disabled={false} />
              <EntityCascaderFormItem showAttributes={false} onChange={() => {}} isCount />
            </>
          );
        };
        return (
          <>
            <div style={{ display: 'inline-flex', gridGap: '10px' }}>
              {varItems()}
            </div>
            <Form.Item
              noStyle
              shouldUpdate={(prev, next) => !isEqual(get(prev, [...valuePath, 'paths']), get(next, [...valuePath, 'paths']))}
              rules={[{ required: true, message: 'Please input a value' }]}
            >
              {
                ({ getFieldValue }) => {
                  const pathArray = getFieldValue([...valuePath, 'paths']);
                  if (!pathArray) return <></>;
                  let pathObject;
                  if (searchId) {
                    pathObject = { entityType: formInstance.getFieldValue([...valuePath, 'nest', 'entityType']) };
                  } else {
                    pathObject = JSON.parse(pathArray[0]);
                  }
                  let toUseEntityType = entityType;
                  if (pathObject.entityType) {
                    toUseEntityType = pathObject.entityType;
                  }
                  formInstance.setFieldValue([...valuePath, 'nest', 'entityType'], toUseEntityType);
                  return (
                    <div className="search-condition-element-type">
                      <AdvanceSearchContext.Provider
                        value={{
                          entityType: toUseEntityType,
                          form: formInstance,
                          searchId
                        }}
                      >
                        <SearchConditionGroupInput
                          inputPath={[...inputPaths, 'nest']}
                          valuePath={[...valuePath, 'nest']}
                          nest
                        />
                      </AdvanceSearchContext.Provider>
                    </div>
                  );
                }
              }
            </Form.Item>
          </>
        );
      },
      /* [SearchConditionEnum.NEST]: () => <div className="search-condition-element-type">
        <SearchConditionGroupInput
          inputPath={[...inputPaths, 'nest']}
          valuePath={[...valuePath, 'nest']}
          entityType={EntityTypeEnum.PERSON}
          nest
        />
      </div>, */
      [SearchConditionEnum.INCLUDE]: () => <EntityCascaderFormItem onChange={onChange} />,
      [SearchConditionEnum.EXCLUDE]: () => <EntityCascaderFormItem onChange={onChange} />,
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  ), []);
  const opt = typeValue as SearchConditionEnumWithoutNest;
  const Component = useMemo(() => test[opt], [test, opt]);
  return <Component valuePath={valuePath} />;
};

const SearchConditionElement: React.FC<SearchConditionElement> = React.memo(
  ({
    inputPaths,
    valuePath,
    removeElement }) => {
    const { entityType, searchId } = useContext(AdvanceSearchContext);
    const [uuidCode] = useState(UUID.v4());
    const { formatMessage } = useLocalization();
    const { colorError } = useColors();
    const formInst = useFormInstance();
    const [selectedCondition,
      setSelectedCondition] = useState<SearchConditionEnum>(formInst.getFieldValue([...valuePath, 'type']));
    const [currentInput, setCurrentInput] = useState<InputElements | null>(null);

    return (
      <div className='search-condition-element-container'>
        <div className="search-condition-header">
          <div
            className={`search-condition-header-inputs
             ${(selectedCondition === SearchConditionEnum.NEST 
              || selectedCondition === SearchConditionEnum.COUNT) ? 'header-inputs-display-block' : ''}`}
          >
            <Form.Item name={[...inputPaths, 'code']} initialValue={uuidCode} hidden>
              <Input />
            </Form.Item>
            <SearchFormItem
              name={[...inputPaths, 'type']}
              rules={[{
                required: true,
                message: 'Please input a value'
              }]}
            >
              <Select
                placeholder='Select Condition'
                style={{ minWidth: '100px', width: '120px' }}
                options={searchConditionOptions(formatMessage)}
                size='small'
                onChange={(value) => {
                  setSelectedCondition(value);
                // formInst.setFieldValue([...valuePath, 'paths'], undefined);
                //  formInst.setFieldValue([...valuePath, 'operation'], undefined);
                //  formInst.setFieldValue([...valuePath, 'value'], undefined);
                }}
              />
            </SearchFormItem>
            <Form.Item
              noStyle
              shouldUpdate={(prev, next) => {
                const prevType = get(prev, [...valuePath, 'type']);
                const nextType = get(next, [...valuePath, 'type']);
                return prevType != nextType;
              }}
            >
              {({ getFieldValue, setFields }) => {
                const typeFieldValue = getFieldValue([...valuePath, 'type']);
                if (!typeFieldValue) return <></>;
                return (
                  <ConditionRenderer
                    inputPaths={inputPaths}
                    valuePath={valuePath}
                    typeValue={typeFieldValue}
                    code={uuidCode}
                    onChange={() => {
                      setFields([
                        {
                          name: [...valuePath, 'inputConfiguration', 'inputType'],
                          value: null
                        },
                        {
                          name: [...valuePath, 'value'],
                          value: null
                        }
                      ]);
                      setCurrentInput(null);
                    }}
                  />
                );
              }}
            </Form.Item>
          </div>
          <DeleteOutlined style={{ color: colorError, position: 'absolute', top: '12px', right: 0 }} onClick={removeElement} />
        </div>
        {/* // starts from her */}


        {
          selectedCondition !== SearchConditionEnum.COUNT && (
            <div
              className={`search-condition-path-values ${currentInput === InputElements.CustomInput
                ? 'custom-input-height' : ''}`}
            >
              {/*  <Form.Item noStyle shouldUpdate={() => true}>
                {
                  ({ getFieldValue }) => {
                    if (!getFieldValue([...valuePath, 'paths'])) return <></>;
                    return (
                      <Form.Item>
                        <Select
                          placeholder='Select operator'
                          defaultValue='like'
                          style={{ width: '100%', minWidth: '100px' }}
                          options={operatorConditions}
                          size='small'
                        />
                      </Form.Item>

                    );
                  }
                }
              </Form.Item> */}
              <Form.Item
                noStyle
                shouldUpdate={(prev, next) => !isEqual(get(prev, [...valuePath, 'paths']), get(next, [...valuePath, 'paths']))}
              >
                {
                  ({ getFieldValue }) => {
                    let attributeValue: { inputType: InputElements };
                    if (searchId) {
                      attributeValue = formInst.getFieldValue([...valuePath, 'inputConfiguration']);
                      const value = formInst.getFieldValue([...valuePath, 'value']);
                      if (attributeValue) {
                        if (!currentInput) {
                          if (value && value.length > 0 && value[0]) {
                            setCurrentInput(InputElements[attributeValue.inputType]);
                          } else {
                            setCurrentInput(InputElements.UserInput);

                          }
                        }
                      }
                    } else {
                      attributeValue = getAttributeData(getFieldValue, [...valuePath, 'paths']);
                    }
                    if (!attributeValue) return <></>;
                    let inputOptions;
                    if (!attributeValue.inputType) {
                      inputOptions = defaultInputOptions(formatMessage);
                    } else {
                      // @ts-ignore
                      inputOptions = inputOptionsGenerator(attributeValue.inputType, formatMessage);
                    }
                    if (currentInput === InputElements.UserInput) {
                      formInst.setFieldValue([...valuePath, 'value'], null);
                    }
                    return (
                      <Form.Item
                        rules={[{ required: true, message: 'Please input a value' }]}
                      >
                        <Select
                          placeholder='Select input condition'
                          style={{ width: '100%', minWidth: '100px' }}
                          value={currentInput}
                          options={inputOptions}
                          size='small'
                          onChange={(val) => setCurrentInput(val)}
                        />
                      </Form.Item>
                    );
                  }
                }
              </Form.Item>

              <Form.Item
                noStyle
                shouldUpdate={(prev, next) => !isEqual(get(prev, [...valuePath, 'paths']), get(next, [...valuePath, 'paths']))}
              >
                {
                  ({ getFieldValue }) => {
                    const attributeValue = getAttributeData(getFieldValue, [...valuePath, 'paths']);
                    if (!attributeValue) return <></>;
                    let inputOptions;
                    const { dataType, inputType } = attributeValue;
                    if (dataType === 'String'
                      && inputType === 'Text') {
                      inputOptions = operatorOptions(true);
                    } else if (attributeValue.dataType === 'Int' && inputType === 'Text') {
                      inputOptions = operatorOptions(false);
                    } else if (attributeValue.inputType === 'Select' || attributeValue.inputType == 'MultiSelect') {
                      formInst.setFieldValue([...valuePath, 'operation'], 'IN');
                      return <></>;
                    } else {
                      return <></>;
                    }
                    return (
                      <Form.Item
                        name={[...inputPaths, 'operation']}
                        rules={[{ required: true, message: 'Please input a value' }]}
                      >
                        <Select
                          placeholder='Select'
                          style={{ width: '100%', minWidth: '100px' }}
                          options={inputOptions}
                          size='small'
                        />
                      </Form.Item>
                    );
                  }
                }
              </Form.Item>
              {
                currentInput && <Form.Item noStyle shouldUpdate={() => true}>
                  {
                    ({ getFieldValue }) => {
                      let attributeValue;
                      let toSetValue = '';
                      let parentEntityType;
                      if (searchId) {
                        attributeValue = formInst.getFieldValue([...valuePath, 'inputConfiguration']);
                        toSetValue = formInst.getFieldValue([...valuePath, 'value']);
                        parentEntityType= attributeValue.parentEntityType;

                      } else {
                        attributeValue = getAttributeData(getFieldValue, [...valuePath, 'paths']);
                        const dataPaths = getFieldValue([...valuePath, 'paths']);
                        const parsedDataPath = JSON.parse(dataPaths[0]);
                        parentEntityType = parsedDataPath?.entityType
                          ? PARENT_ENTITY_TYPE.indexOf(parsedDataPath.entityType) == -1
                            ? entityType: parsedDataPath.entityType: entityType;
                      }
                      if (!attributeValue) return <></>;
                      const configs: AdvanceSearchInputCriteria = {
                        service: attributeValue.serviceName || attributeValue.valueService,
                        heading: attributeValue.heading || attributeValue.inputHeading,
                        isHeadingKey: attributeValue.isHeadingKey || attributeValue.isInputHeadingKey,
                        inputType: attributeValue.inputType === 'MultiSelect' ? InputElements.Select : attributeValue.inputType,
                        inputHeadingMeta: attributeValue.inputHeadingMeta,
                        parentEntityType,
                        placeholder: '',
                        code: attributeValue.code,
                        criteriaId: attributeValue.code,
                        value: toSetValue
                      };
                      return (
                        <SearchConditionElementInput
                          inputPaths={[...inputPaths]}
                          valuePaths={valuePath}
                          inputType={currentInput}
                          inputConfigs={configs}
                        />
                      );
                    }
                  }
                </Form.Item>
              }
            </div>
          )
        }
      </div>
    );
  }
);
export default SearchConditionElement;
