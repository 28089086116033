import React, { useRef } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { get, toNumber } from 'lodash';
import { NavLink, useMatch, useNavigate } from 'react-router-dom';
import { Button, Col, Popconfirm, Result, Row, Space } from 'antd';
import moment from 'moment';
import FormatSelect from '../components/FormatSelect';
import DisplayContent from '../../../components/Badge/DisplayContent';
import ListDetailsRecordsTable, { ListDetailsRecordsTableRef } from './ListDetailsRecordsTable';
import DynamicRules from './DynamicRules';
import { usePageTitle } from '../../../hooks/usePageTitle';
import ListDetailsShared from '../components/ListDetailsShared';
import ListDetailsStar from '../components/ListDetailsStar';
import MainView from '../../../components/View/MainView';
import { isActualNumber } from '../../../util/Util';
import { Locale } from '../../../../localization/LocalizationKeys';
import { useLocalization } from '../../../util/useLocalization';
import FlagSelector from '../../../components/Flag/FlagSelector';
import { FlagCountry } from '../../../components/Flag/Flag';
import { SupportedEntitySearchTypes } from '../../search_old/types';
import {
  DeactivateListMutationMutation,
  DeactivateListMutationMutationVariables,
  ListDetailsQueryQuery, ListDetailsQueryQueryVariables,
  ListTypeEnum
} from '../../../../gql/typings';
import { DATE_FORMAT } from '../../../util/format';

const ListDetails = () => {
  const match = useMatch('/list/:id');
  const navigate = useNavigate();
  const listId = match?.params?.id;
  const localization = useLocalization();

  if (!isActualNumber(listId)) return (
    <MainView className="list-details-error-view">
      <Result
        status="403"
        title={localization.formatMessage(Locale.Text.Not_found)}
        extra={<NavLink to="/list">
          {localization.formatMessage(Locale.Command.Back)}
        </NavLink>}
      />
    </MainView>
  );

  const tableRef = useRef<ListDetailsRecordsTableRef>(null);
  const [deactivateList] = useMutation<DeactivateListMutationMutation, DeactivateListMutationMutationVariables>(DeactivateList);
  const { data, loading, error } = useQuery<ListDetailsQueryQuery, ListDetailsQueryQueryVariables>(LIST_DETAILS_DATA_QUERY, {
    skip: !isActualNumber(listId),
    variables: { listId: isActualNumber(listId) ? toNumber(listId) : -1 },
  });
  usePageTitle(data?.list?.title);

  const PermissionToListDenied: React.FC = props => (
    <MainView className="list-details-error-view">
      <Result
        status="403"
        title={localization.formatMessage(Locale.Text.Permission_to_view_list_denied)}
        subTitle={get(props, 'data.error.graphQLErrors[0].message', '')}
        extra={<NavLink to="/list">
          {localization.formatMessage(Locale.Command.Back)}
        </NavLink>}
      />
    </MainView>
  );

  if (!loading && error) return <PermissionToListDenied />;

  const editAccess = data?.list?.editAccess ?? false;
  const isDynamic = data?.list?.typeEnum === ListTypeEnum.DYN;

  const RightActions = (
    <Space>
      <FormatSelect listId={toNumber(listId)} />
      <Popconfirm
        placement="left"
        title={localization.formatMessage(Locale.Text.Deleting_this_list_will_permanently_remove_the_list_from_the_system)}
        cancelText={localization.formatMessage(Locale.Command.Cancel)}
        okText={localization.formatMessage(Locale.Command.Confirm)}
        onConfirm={() => {
          if (data?.list?.id) {
            deactivateList({ variables: { id: data.list.id } }).then(() => navigate('/list'));
          }
        }}
      >
        <Button type='default' danger>
          {localization.formatMessage(Locale.Command.Delete)}
        </Button>
      </Popconfirm>
    </Space>
  );

  const DisplayValue: React.FC<{
    label: React.ReactNode;
    value: string|React.ReactNode;
    render?: React.FC;
  }> = ({ label, value, render: Render = DisplayContent }) => <>
    <span className="label">
      {label}:
    </span>
    <span className="value">
      <Render>
        {value}
      </Render>
    </span>
  </>;

  const DateDisplay: React.FC<{ format?: string; children?: string | undefined }> = props => {
    if (!props.children) return <></>;
    return <>{moment(props.children).format(props.format ?? DATE_FORMAT)}</>;
  };

  return (
    <MainView
      className="list-detail-view-content-container"
      rightActions={RightActions}
      titleLoading={loading}
      title={{
        entityId: listId,
        title: data?.list?.title ?? '',
        editAccess,
        valueTypeLabel: 'title',
        gqlMutation: UPDATE_TITLE_MUTATION,
      }}
      subComponent={{
        entityId: listId,
        title: data?.list?.description ?? '',
        editAccess,
        valueTypeLabel: 'description',
        gqlMutation: UPDATE_DESCRIPTION_MUTATION,
      }}
    >
      <Row style={{ marginTop: 20 }}>
        <Col sm={24} md={18}>
          <div className="data-values">
            <DisplayValue
              label={localization.formatMessage(Locale.Attribute.Created)}
              value={get(data, 'list.ct')}
              render={DateDisplay}
            />
            <DisplayValue
              label={localization.formatMessage(Locale.Attribute.Type)}
              value={data?.list?.listType.heading ?? ''}
            />
            <DisplayValue
              label={localization.formatMessage(Locale.Attribute.Updated)}
              value={data?.list?.ut ?? ''}
              render={DateDisplay}
            />
            <DisplayValue
              label={localization.formatMessage(Locale.General.Entity)}
              value={data?.list?.entityType?.heading ?? ''}
            />
            <DisplayValue
              label={localization.formatMessage(Locale.Attribute.Created_by)}
              value={data?.list?.createdUser?.fullname ?? ''}
            />
            {data?.list?.countries.nodes && data.list.countries.nodes.length > 0 && <DisplayValue
              label={localization.formatMessage(Locale.Attribute.Countries)}
              value={<FlagSelector rectangle selected={data.list.countries.nodes.map(c => c.countryCode as FlagCountry)} />}
            />}
          </div>
        </Col>
        <Col sm={24} md={6}>
          <div className="right-share-actions">
            <div className="element">
              <h3>{localization.formatMessage(Locale.General.Shared)}:</h3>
              <ListDetailsShared listId={toNumber(listId)} />
            </div>
            <div className="element">
              <h3>{localization.formatMessage(Locale.General.Favorite)}:</h3>
              <ListDetailsStar listId={listId} />
            </div>
          </div>
        </Col>
      </Row>
      {isDynamic && <DynamicRules
        listId={listId}
        entityTypeEnum={data!.list!.entityTypeEnum as SupportedEntitySearchTypes}
        totalCount={data?.list?.criterias?.totalCount}
        onUpdate={() => tableRef.current?.refetch()}
      />}
      <Row style={{ marginTop: 20 }}>
        {data?.list?.entityTypeEnum && <ListDetailsRecordsTable
          ref={tableRef}
          listId={toNumber(listId)}
          entityType={data.list.entityTypeEnum as SupportedEntitySearchTypes}
        />}
      </Row>
    </MainView>
  );
};


export const LIST_DETAILS_DATA_QUERY = gql`
  query ListDetailsQuery($listId: Int!) {
    list(id: $listId) {
      id
      isFavoriteList
      isShared
      description
      title
      sort
      ct
      ut
      editAccess
      entityTypeEnum
      typeEnum
      countries {
        hash
        nodes { 
          id
          countryCode
        }
      }
      entityType {
        code
        heading
      }
      criterias {
        hash
        totalCount
      }
      listType {
        code
        heading
      }
      createdUser {
        id
        fullname
      }
    }
  }
`;

const UPDATE_TITLE_MUTATION = gql`
  mutation UpdateListTitle($id: Int!, $value: String!) {
    updateList(input: {id: $id, title: $value}) {
      id
      title
    }
  }
`;
const UPDATE_DESCRIPTION_MUTATION = gql`
  mutation UpdateListDescription($id: Int!, $value: String) {
    updateList(input: {id: $id, description: $value}) {
      id
      description
    }
  }
`;

const DeactivateList = gql`
  mutation DeactivateListMutation(
    $id: Int!
  ) {
    deactivateList(id: $id) { id, isActive }
  }
`;

export default ListDetails;
