import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Alert, Button, Space, Tooltip } from 'antd';
import { Layout } from 'react-grid-layout';
import { StateArray } from '../../../util/StateArrayType';
import { Locale } from '../../../../localization/LocalizationKeys';
import { usePermission } from '../../../permission/usePermission';
import AddNewDashboardModal from './AddNewDashboardModal';
import { DashboardItemsQueryQuery } from '../../../../gql/typings';


type DashboardGridTopActionsProps = {
  editModeState: StateArray<boolean>;
  recentlyCreatedState: StateArray<number>;
  dashboard: NonNullable<DashboardItemsQueryQuery['dashboard']>;
  layoutState: StateArray<Layout[]>;
  onSave: () => void;
};

const DashboardGridTopActions: React.FC<DashboardGridTopActionsProps> = ({
  editModeState: [editMode, setEditMode],
  layoutState: [layouts, setLayouts],
  recentlyCreatedState: [recentlyCreated, setRecentlyCreated],
  onSave,
  dashboard,
}) => {
  const intl = useIntl();
  const [isAddNewItemModalVisible, setIsAddNewItemModalVisible] = useState(false);
  const [layoutsBackup, setLayoutsBackup] = useState<Layout[]>();
  const { update } = usePermission('DASHBOARD_DEFAULT');

  return (
    <div className="dashboard-grid-top-actions-container">
      <div className="buttons">
        <div className="left" />
        <div className="right">
          <Space>
            {editMode && (
              <Button onClick={() => setIsAddNewItemModalVisible(true)} type='dashed'>
                {intl.formatMessage(Locale.Text.Add_to_grid)}
              </Button>
            )}
            {!editMode && !(dashboard.isDefaultDashboard && !update) && (
              <Tooltip
                placement="left"
                title={dashboard.isDefaultDashboard ? intl.formatMessage(Locale.Text.Modify_dashboard_item_tooltip_rescriction)
                  : intl.formatMessage(Locale.Text.Modify_dashboard_item_tooltip)}
              >
                <Button
                  disabled={dashboard.isDefaultDashboard}
                  onClick={() => {
                    setLayoutsBackup(layouts);
                    setEditMode(true);
                  }}
                >
                  {intl.formatMessage(Locale.Text.Dashboard_configure_dashboards)}
                </Button>
              </Tooltip>
            )}
            {editMode && (
              <Button onClick={() => {
                setLayouts(layoutsBackup ?? layouts);
                setEditMode(false);
              }}
              >
                {intl.formatMessage(Locale.Command.Cancel)}
              </Button>
            )}
            {editMode && (
              <Button onClick={onSave} type="primary">
                {intl.formatMessage(Locale.Text.Update_grid)}
              </Button>
            )}
          </Space>


        </div>
      </div>

      {editMode && <section className="edit-mode-warning">
        <Alert
          style={{ marginTop: '5px' }}
          message={intl.formatMessage(Locale.Text.Dashboard_information_configuration_tooltip)}
          type="info"
        />
      </section>}

      <AddNewDashboardModal
        dashboardId={dashboard.id}
        recentlyCreatedState={[recentlyCreated, setRecentlyCreated]}
        editState={[editMode, setEditMode]}
        visibleState={[isAddNewItemModalVisible, setIsAddNewItemModalVisible]}
      />
    </div>
  );
};

export default DashboardGridTopActions;
