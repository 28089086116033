import React, { useContext, useEffect, useState } from 'react';
import { Form, Modal } from 'antd';
import { EntitiesSearch } from '../../../../components/entitiesSearch';
import { ActivityDetailContext } from '../ActivityDetailsTypes';
import { UseActivityPersonSelectionReturnTypeOld } from './useActivityPersonSelection';
import DetailsPageSection from '../DetailsPageSection';
import { Locale } from '../../../../../localization/LocalizationKeys';
import { useTableRowSelection } from '../../../../components/Table/useTableRowSelection';
import { useLocalization } from '../../../../util/useLocalization';
import EntityTable from '../../../../components/entitiesSearch/results/EntityTable';
import { EntityTypeEnum } from '../../../../../gql/typings';


const ActivityMultiPersonsDetails: React.FC<{ use: UseActivityPersonSelectionReturnTypeOld }> = ({
  use: {
    personsToShow,
    totalCount,
    loading,
    addPersons,
    removePersons,
    tableSelection,
  },
}) => {
  const localization = useLocalization();
  const [inModalForm] = Form.useForm();
  const { activityId } = useContext(ActivityDetailContext);
  const [showEntitiesSearch, setShowEntitiesSearch] = useState(false);
  const inModalSelectionState = useTableRowSelection({ form: inModalForm });

  useEffect(() => {
    tableSelection.tableProps.onVisibleKeysChange(personsToShow.map(p => p.id));
    tableSelection.tableProps.onDataChange({ totalCount });
  }, [personsToShow, tableSelection, totalCount]);

  return (
    <DetailsPageSection
      heading={localization.formatMessage(Locale.General.Persons_HCP)}
      className="activity-multi-persons-details-container"
    >
      <EntityTable
        entityType={EntityTypeEnum.PERSON}
        tableSelection={tableSelection}
        tableProps={{ className: 'activity-multi-persons-details-table-container' }}
        showCreateEntityButton={false}
        quickActions={[
          {
            key: 'open-add-persons-modal',
            className: 'open-add-persons-modal',
            label: localization.formatMessage(Locale.Command.Add_Persons),
            type: 'dashed',
            loading,
            onClick: () => setShowEntitiesSearch(true),
          },
          {
            key: 'remove-persons',
            hide: tableSelection.selection.count === 0,
            label: localization.formatMessage(Locale.Command.Remove_Persons),
            type: 'default',
            danger: true,
            onClick: () => removePersons(tableSelection.selection).finally(() => tableSelection.clearState()),
            loading,
            confirm: !!activityId && {
              label: localization.formatMessage(Locale.Text.Are_you_sure_to_remove_persons_from_activity),
            },
          },
        ]}
        data={{
          loading,
          totalCount,
          nodes: personsToShow,
        }}
      />

      {/* <Table */}
      {/*  {...tableProps} */}
      {/*  {...personTableProps} */}
      {/* /> */}
      <Modal
        open={showEntitiesSearch}
        onCancel={() => setShowEntitiesSearch(false)}
        footer={null}
        width="80%"
      >
        <EntitiesSearch
          entityType={EntityTypeEnum.PERSON}
          form={inModalForm}
          tableSelection={inModalSelectionState}
          criteriaFilter={{
            excludeActivityId: activityId,
            excludeIds: activityId || personsToShow.length === 0 ? null : personsToShow.map(p => p.id),
          }}
          quickActions={[{
            key: 'select-person',
            className: 'select-persons',
            type: 'dashed',
            hide: inModalSelectionState.selection.count === 0,
            label: localization.formatMessage(Locale.Text.Add_people_to_activity),
            onClick: () => {
              setShowEntitiesSearch(false);
              return addPersons(inModalSelectionState.selection);
            },
          }]}
        />
      </Modal>
    </DetailsPageSection>
  );
};


export default ActivityMultiPersonsDetails;
