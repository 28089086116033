import React, { forwardRef, useContext, useEffect, useImperativeHandle, useState } from 'react';
import { message, Button, Form, Checkbox, Input, Tooltip, Switch } from 'antd';
import { gql } from '@apollo/client/core';
import { useMutation } from '@apollo/client';
import * as UUID from 'uuid';
import { AdvanceSearchContext } from './AdvancedSearchBuilder';
import {
  MyAdvanceSavedSearchesDocument,
  saveAdvanceSearchMutation,
  saveAdvanceSearchMutationVariables
} from '../../../../gql/typings';
import { formatAdvanceSearch } from './format';
import { useLocalization } from '../../../util/useLocalization';
import { Locale } from '../../../../localization/LocalizationKeys';
import { LightCollapseContext } from '../../LightCollapse/LightCollapse';
import { sanitizeObject } from '../../../util/Util';

type SearchForm = {
  searchName: string;
  description: string;
  shared: boolean;
};

interface AdvanceSearchSaveProps {
  previousStep: () => void;
}

export type AdvanceSearchSaveRef = {
  resetForm: () => void;
};

const AdvanceSearchSave = forwardRef<AdvanceSearchSaveRef, AdvanceSearchSaveProps>(({
  previousStep
}, ref) => {
  const [saveSearchForm] = Form.useForm();
  const {
    form: searchInputForm,
    setSearchStep,
    searchId
  } = useContext(AdvanceSearchContext);
  const { onSetActive } = useContext(LightCollapseContext);
  const [messageApi, contextHolder] = message.useMessage();
  const [saveSearch, { loading }] = useMutation<saveAdvanceSearchMutation
  , saveAdvanceSearchMutationVariables>(SAVE_ADVANCE_SEARCH_MUTATION);
  const heading = Form.useWatch('heading', { form: searchInputForm, preserve: true });
  const [createNewSearch, setCreateNewSearch] = useState<boolean>();
  const { formatMessage } = useLocalization();

  useEffect(() => {
    if (searchId && searchId > 0) {
      const heading = searchInputForm?.getFieldValue('heading');
      saveSearchForm.setFieldValue('searchName', heading);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [heading]);

  const autoGeneratePrefix = (
    <Tooltip placement='top' title={formatMessage(Locale.Command.Auto_generate_name)}>
      <Checkbox onChange={e => {
        const checkStatus = e.target.checked;
        if (checkStatus) {
          saveSearchForm.setFieldValue('searchName', 'Auto Generated Name');
        }
      }}
      />
    </Tooltip>
  );

  const onResetSearch = (onlyForm?: boolean) => {
    searchInputForm?.resetFields();
    saveSearchForm.resetFields();
    searchInputForm?.setFieldsValue({
      code: UUID.v4().toString(),
      groups: []
    });
    saveSearchForm.setFieldsValue({});
    if (onlyForm) return;
    if (setSearchStep) {
      setSearchStep(0);
    }
    if (!createNewSearch) {
      onSetActive && onSetActive('1');
    }
  };

  const onSaveSearch = (values: SearchForm) => {
    const searchInput = sanitizeObject(
      formatAdvanceSearch(searchInputForm?.getFieldsValue(true)),
      ['__typename', 'id', 'dataType', 'criteriaId']
    );
    const toSubmitValues: saveAdvanceSearchMutationVariables = {
      type: searchInput.entityType,
      heading: values.searchName,
      isShared: values.shared,
      searchInput: {
        search: searchInput,
        ...(searchId && searchId > 0 ? { searchId }: {}),
        values: []
      },
    };

    saveSearch({
      variables: toSubmitValues,
      refetchQueries: [{
        query: MyAdvanceSavedSearchesDocument,
        fetchPolicy: 'network-only'
      }]
    }).then(() => {
      // reset code
      onResetSearch();
      messageApi.success('Query has been saved!').then();
    });
  };

  // eslint-disable-next-line
  const onFormSubmit = () => {
    saveSearchForm.validateFields().then(
      (values) => {
        onSaveSearch(values);
      }
    );


  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
  };

  useImperativeHandle(ref, () => (
    {
      resetForm: () => onResetSearch(true)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }), []);

  return (
    <div className='advance-search-save-container'>
      {contextHolder}
      <div className="advance-search-save-form">
        <Form
          form={saveSearchForm}
          onFinish={onFormSubmit}
          {...formItemLayout}
        >
          <Form.Item
            name="searchName"
            label="Search Name"
            rules={[
              {
                required: true,
                message: 'Please fill search name'
              }
            ]}
          >
            <Input addonBefore={autoGeneratePrefix} style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
          >
            <Input.TextArea rows={4} style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            name="shared"
            label="Shared"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>

          <div className='create-new-search'>
            <Checkbox onChange={e => {
              setCreateNewSearch(e.target.checked);
            }}
            >Create a new search</Checkbox>
          </div>
          {/*  <Form.Item
            name="color"
            label="Color"
          >
              <ColorPicker trigger='hover' />
          </Form.Item> */}
          <div className="action-button-container">
            <Button
              style={{ marginRight: '5px' }}
              onClick={previousStep}
            >
              Back
            </Button>
            <Button loading={loading} htmlType='submit' type='primary'>
              {searchId && searchId > 0 ? 'Edit' :formatMessage(Locale.Text.Save)}
            </Button>
          </div>
        </Form>
      </div>
    </div>

  );
});
export default AdvanceSearchSave;

export const SAVE_ADVANCE_SEARCH_MUTATION = gql`
  mutation saveAdvanceSearch($type: EntityTypeEnum!, $heading: String!, $searchInput: SaveSearchInput!, $isShared: Boolean!) {
    saveSearch(type: $type, heading: $heading, searchInput: $searchInput, isShared: $isShared) {
      id
      code
      heading
      isHeadingKey
    }
  }
`;

