import React, { Dispatch, SetStateAction, useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { Button, Form, FormInstance, Input, Steps, Typography } from 'antd';
import * as UUID from 'uuid';
import { gql, useLazyQuery } from '@apollo/client';
import SearchConditionBuilder from './SearchConditionBuilder';
import { Locale } from '../../../../localization/LocalizationKeys';
import {
  EntityTypeEnum, GetAdvanceSearchDataQueryQuery, GetAdvanceSearchDataQueryQueryVariables, InputMaybe,
  SearchInput
} from '../../../../gql/typings';
import AdvanceSearchSave, { AdvanceSearchSaveRef } from './AdvanceSearchSave';
import { useLocalization } from '../../../util/useLocalization';
import { StateArray } from '../../../util/StateArrayType';
import { SEARCH_NODE_FRAGMENT } from '../entitiesSearchAdvance';

type AdvanceSearchContextType = {
  entityType: EntityTypeEnum;
  form?: FormInstance | undefined;
  setSearchStep?: Dispatch<SetStateAction<number>> | undefined;
  searchId?: InputMaybe<number>;
};

export const AdvanceSearchContext = React.createContext<AdvanceSearchContextType>(
  { entityType: EntityTypeEnum.PERSON, form: undefined, setSearchStep: undefined, searchId: undefined }
);

type AdvancedSearchBuilderProps = {
  entityType: EntityTypeEnum;
  editState: StateArray<number>;
};

const AdvancedSearchBuilder: React.FC<AdvancedSearchBuilderProps> = ({ entityType, editState }) => {
  const [uuidCode] = useState(UUID.v4().toString());
  const initialValue = { entityType, code: uuidCode, groups: [] };
  const [advanceSearchForm] = Form.useForm<SearchInput>();
  const advanceSearchSaveRef = useRef<AdvanceSearchSaveRef>(null);
  // const [tableMapperMode, setTableMapperMode] = useState(IsMappingJoinBasicEnum.BASIC);
  /*  const { data } = useQuery<AdvancedSearchBuilderQuery, AdvancedSearchBuilderQueryVariables>(DATA_QUERY, {
    variables: {
      entityType,
      mode: tableMapperMode,
    },
  }); */
  const [getAdvanceSearch, {
    data: advanceSearchData
  }] = useLazyQuery<GetAdvanceSearchDataQueryQuery,
  GetAdvanceSearchDataQueryQueryVariables>(GET_ADVANCE_SEARCH_DATA_QUERY, {
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache'
  });
  const localization = useLocalization();
  const [searchStep, setSearchStep] = useState(0);

  const resetForm = useCallback(() => {
    advanceSearchSaveRef?.current?.resetForm(); 
    // specify name of fields to unsure reset values to initial
    advanceSearchForm.resetFields(['code', 'groups', 'entityType', 'heading', 'searchId']);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (editState[0] > 0) {
      getAdvanceSearch({
        variables: {
          searchId: editState[0]
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editState[0]]);

  useEffect(() => {
    if (advanceSearchData) {
      resetForm();
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { id, ...rest } = advanceSearchData.singleSearch;
      advanceSearchForm.setFieldsValue(rest);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advanceSearchData?.singleSearch.id]);

  const endEditing = () => {
    resetForm();
    editState[1](-1);
  };

  const editModeHandler = useMemo((): number | undefined => {
    //  reset form data  if search is editing but form has been touched before it
    if (advanceSearchForm.isFieldsTouched()) {       
      endEditing();
    }
    if (editState[0] > 0) {
      return editState[0];
    }
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editState]);

  return (
    <div className="advanced-search-builder-container">
      {
        editState[0] > 0 && <div className="advanced-search-edit-end-button-container">
          <Typography.Title>
            {advanceSearchData?.singleSearch.heading}
          </Typography.Title>
          <Button onClick={endEditing}>End Editing</Button>
        </div>
      }
      <Typography.Paragraph>
        {localization.formatMessage(Locale.Text.Advance_search_description)}
      </Typography.Paragraph>
      <AdvanceSearchContext.Provider value={{
        entityType,
        form: advanceSearchForm,
        setSearchStep,
        searchId: editModeHandler
      }}
      >
        <Steps
          style={{ margin: '25px 0' }}
          current={searchStep}
          items={[
            {
              title: localization.formatMessage(Locale.Text.Build_search),
            },
            {
              title: localization.formatMessage(Locale.Text.Save_search),
            }
          ]}
          onChange={(e) => setSearchStep(e)}
        />
        <div className={searchStep === 1 ? 'hide-content' : ''}>
          <Form form={advanceSearchForm} initialValues={initialValue}>
            <Form.Item name='entityType' hidden>
              <Input value={entityType} />
            </Form.Item>
            <Form.Item name='code' hidden>
              <Input value={uuidCode} />
            </Form.Item>
            <SearchConditionBuilder nextStep={() => {
              setSearchStep(1);
            }}
            />
          </Form>
        </div>
        <div className={searchStep === 0 ? 'hide-content' : ''}>
          <AdvanceSearchSave ref={advanceSearchSaveRef} previousStep={() => setSearchStep(0)} />
        </div>
      </AdvanceSearchContext.Provider>
    </div>
  );
};

export default AdvancedSearchBuilder;

export const GET_ADVANCE_SEARCH_DATA_QUERY = gql`
  query GetAdvanceSearchDataQuery($searchId: ID!) {
    singleSearch(id: $searchId) {
      ...SearchNodesFragment
    }
  }
  ${SEARCH_NODE_FRAGMENT}
`;

