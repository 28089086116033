import React from 'react';
import { Button, Popconfirm } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { Locale } from '../../../localization/LocalizationKeys';

export type QuickActionButtonProps = {
  hide?: boolean;
  disabled?: boolean;

  label: string|React.ReactNode;
  onClick: () => void;
  loading?: boolean;
  size?: SizeType;
  type?: 'default' | 'primary' | 'ghost' | 'dashed' | 'link' | 'text' | 'action' | undefined;
  className?: string;
  danger?: boolean;

  confirm?: false|{
    label: string|React.ReactNode;
    icon?: React.ReactNode;
    okLabel?: string;
    cancelLabel?: string;
    disabled?: boolean;
    overlayClassName?: string;
  };
};

const QuickActionButton: React.FC<QuickActionButtonProps> = (props) => {
  const { formatMessage } = useIntl();

  if (props.hide) return <></>;

  const btn = (
    <Button
      size={props.size}
      disabled={props.disabled}
      onClick={props.confirm ? () => {} : props.onClick}
      loading={props.loading}
      className={`${props.type === 'action' || !props.type ? 'action-to-take' : ''} ${props.className}`}
      type={props.type === 'action' ? undefined : !props.type ? 'default' : props.type}
      danger={props.danger}
    >
      {props.label}
    </Button>
  );

  return (
    <span className="quick-action-button-container">
      {!props.confirm || props.disabled
        ? btn
        : (
          <Popconfirm
            title={props.confirm.label}
            placement="topRight"
            onConfirm={props.onClick}
            overlayClassName={props.confirm.overlayClassName}
            icon={props.confirm.icon || <ExclamationCircleOutlined />}
            okText={props.confirm.okLabel || formatMessage(Locale.General.Yes)}
            cancelText={props.confirm.cancelLabel || formatMessage(Locale.General.No)}
            okButtonProps={{ disabled: props.confirm.disabled || false }}
          >
            {btn}
          </Popconfirm>
        )}
    </span>
  );
};

export default QuickActionButton;
