import React from 'react';
import { Button, Dropdown, MenuProps } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { ButtonShape } from 'antd/lib/button';
import { NavLink } from 'react-router-dom';

export type ButtonComponentProps = {
  text: React.ReactNode;
  disabled?: boolean;
  loading?: boolean;
  size?: SizeType;
  buttonType?: 'default' | 'primary' | 'ghost' | 'dashed' | 'link' | 'text' | 'action' | undefined;
  shape?: ButtonShape;
  icon?: React.ReactNode;
  url?: string;
  menuItems?: {
    key: string | number;
    label: string|React.ReactNode;
    onClick?: () => void;
    hide?: boolean;
    disabled?: boolean;
  }[];
};

const ApbButton: React.FC<ButtonComponentProps> = ({
  text,
  buttonType = 'default',
  size = 'middle',
  disabled = false,
  icon,
  shape,
  loading,
  menuItems,
  url,
}) => {
  const btn = <Button
    shape={shape}
    loading={loading}
    disabled={disabled}
    size={size}
    className={buttonType === 'action' ? 'button-type-action': ''}
    type={buttonType === 'action' ? undefined : buttonType}
  >
    {text} {icon}
  </Button>;

  if (!menuItems || menuItems.length === null) return !url
    ? <span className="button-component-container">{btn}</span>
    : <div className="button-component-container">
      <NavLink to={url}>{btn}</NavLink>
    </div>;

  const items = menuItems.filter(item => !item.hide) as MenuProps['items'];

  return (
    <div className="button-component-container">
      <Dropdown menu={{ items }} trigger={['click']}>
        <span onClick={e => e.preventDefault()}>
          {btn}
        </span>
      </Dropdown>
    </div>
  );
};


export default ApbButton;

