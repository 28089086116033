import React, { ChangeEvent } from 'react';
import { Button, Checkbox, Form, Input, Space, Switch } from 'antd';
import { identity, isEqual } from 'lodash';
import { FormProps } from 'antd/lib/form/Form';
import { gql, useQuery } from '@apollo/client';
import { useLocalization } from '../../../util/useLocalization';
import { Locale } from '../../../../localization/LocalizationKeys';
import { Optional, StateArray } from '../../../util/StateArrayType';
import CountryPicker from '../../../components/Input/Fields/CountryPicker';
import SelectSiteType from '../../../components/Input/Fields/SelectSiteType';
import CreateAddAddressModal from '../../person/create/CreateAddAddressModal';
import GlowButton from '../../../components/Input/GlowButton';
import ContactFormInput from '../../person/Components/ContactFormInput';
import ValueChainsFormInput from '../components/ChainFormInput';
import SiteSpecialityFormInput from '../components/SiteSpecialityFormInput';
import SiteSectorFormInput from '../components/SiteSectorFormInput';
import SiteAssignFormInput from '../components/SiteAssignFormInput';
import { AddressInput, EntityTypeEnum, SiteInput, TotalAddressTypeQueryQuery } from '../../../../gql/typings';
import Loading from '../../../components/Loading/Loading';
import { useBroadcastStorage } from '../../../util/useBroadcastStorage';

type CreateSiteFieldsProps = {
  keyCodes?: string[];
  allowChooseMaintainer: boolean;
  noDepartmentState: StateArray<boolean>;
  formItemLayout: Pick<FormProps, 'labelCol'|'wrapperCol'>;
  formItemLayoutWithOutLabel: Pick<FormProps, 'wrapperCol'>;
};

export const createSiteFields: Record<keyof SiteInput, React.FC<CreateSiteFieldsProps>> = {
  name: () => {
    const localization = useLocalization();
    return (
      <Form.Item
        name="name"
        rules={[{ required: true }]}
        label={localization.formatMessage(Locale.Attribute.Site_name)}
      >
        <Input
          autoComplete="newpassword"
          placeholder={localization.formatMessage(Locale.Command.Enter_Name)}
        />
      </Form.Item>
    );
  },
  departmentName: ({ noDepartmentState }) => {
    const localization = useLocalization();
    return (
      <Form.Item
        name="departmentName"
        rules={[{ required: !noDepartmentState[0] }]}
        label={localization.formatMessage(Locale.Attribute.Department)}
      >
        <DepartmentInput noDepartmentState={noDepartmentState} />
      </Form.Item>
    );
  },
  country: () => {
    const localization = useLocalization();
    return (
      <Form.Item
        name="country"
        rules={[{ required: true }]}
        label={localization.formatMessage(Locale.Attribute.Country)}
      >
        <CountryPicker />
      </Form.Item>
    );
  },
  typeCode: () => {
    const localization = useLocalization();
    return (
      <Form.Item noStyle shouldUpdate={(prev, curr) => prev.country !== curr.country}>
        {({ getFieldValue }) => (
          <Form.Item
            rules={[{ required: true }]}
            label={localization.formatMessage(Locale.Attribute.Type)}
            name="typeCode"
          >
            <SelectSiteType countryCode={getFieldValue('country')} />
          </Form.Item>
        )}
      </Form.Item>
    );
  },
  addresses: ({ formItemLayout, formItemLayoutWithOutLabel }) => {
    const localization = useLocalization();
    const { data, loading } = useQuery<TotalAddressTypeQueryQuery>(TOTAL_ADDRESS_TYPE);
    const [openModal, setOpenModal] = React.useState(false);
    const copyAddress = useBroadcastStorage<boolean>('copy-address', false);    
    
    if (loading) return <Loading />;

    return (
      <Form.Item
        shouldUpdate={(prev, curr) => (prev.country !== curr.country) || !isEqual(prev.addresses, curr.addresses)}
        noStyle
      >
        {({ getFieldValue }) => (
          <Form.List name="addresses">
            {(fields, { add, remove }) => (
              <div className="addresses">
                {fields.map((field, index) => {
                  const addressList = getFieldValue('addresses');
                  let toBePatched = { addressTypeCode: '' };
                  if (addressList[0]) {
                    toBePatched = { ...addressList[0] };
                  }
                  return (
                    <Form.Item
                      key={field.key}
                      label={index === 0 ? localization.formatMessage(Locale.Attribute.Address) : undefined}
                      {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                    >
                      <Space>
                        <Form.Item
                          {...field}
                          noStyle
                          validateTrigger='onSubmit'
                          rules={[{
                            validator: (rule, value: Optional<Partial<AddressInput>>) => {
                              if (!value?.addressTypeCode) {
                                return Promise.reject(new Error('No address type has been selected'));
                              }
                              if ([
                                value?.postalCode,
                                value?.city,
                                value?.street,
                                value?.communeCode,
                              ].filter(identity).length > 0) return Promise.resolve();

                              return Promise.reject(new Error("Can't create an empty address"));
                            }
                          }]}
                        >
                          <CreateAddAddressModal
                            toPatchValue={toBePatched}
                            countryCode={getFieldValue('country')}
                            entityType={EntityTypeEnum.SITE}
                            usedAddressTypes={getFieldValue('addresses').map(
                              (x: AddressInput) => x?.addressTypeCode
                            )}
                            showCopy={index === 0}
                            isOpen={index < 2 && openModal}
                            onFinish={() => {
                              setOpenModal(false); 
                              if (index === 0 && copyAddress[0]) {
                                add();
                              }
                            }}
                          />
                        </Form.Item>
                        <GlowButton type="remove" size="x-small" onClick={() => remove(field.name)} />
                      </Space>
                    </Form.Item>
                  );
                })}
                {data
                    && fields.length < (data.addressTypes.totalCount - 1)
                    && <Form.Item
                      label={fields.length === 0 ? localization.formatMessage(Locale.Attribute.Address) : undefined}
                      {...(fields.length === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                    >
                      <Button
                        onClick={() => {
                          setOpenModal(true);  
                          add();                         
                        }}
                      >Add address</Button>
                    </Form.Item>}
              </div>
            )}
          </Form.List>
        )}
      </Form.Item>
    );
  },
  notes: () => {
    const localization = useLocalization();
    return (
      <Form.Item name="notes" label={localization.formatMessage(Locale.Attribute.Note)}>
        <Input.TextArea
          placeholder={localization.formatMessage(Locale.Command.Enter_Notes)}
        />
      </Form.Item>
    );
  },
  instantValidation: () => {
    const localization = useLocalization();
    const [instantValidate, setInstantValidate] = useBroadcastStorage('instant-validation', true);
    return (
      <Form.Item
        name='instantValidation'
        label={localization.formatMessage(Locale.Attribute.Instant_Validation)}
        valuePropName={instantValidate ? 'checked': ''}
        initialValue={instantValidate}
      >
        <Switch
          checkedChildren="Yes"
          unCheckedChildren="No"
          onChange={e => setInstantValidate(e)}
        />
      </Form.Item>
    );
  },
  numberOfBeds: () => {
    const localization = useLocalization();
    return (
      <Form.Item
        name="numberOfBeds"
        label={localization.formatMessage(Locale.Attribute.Number_of_beds)}
      >
        <Input
          autoComplete="newpassword"
          placeholder={localization.formatMessage(Locale.Command.Enter_Number_Of_Beds)}
        />
      </Form.Item>
    );
  },
  numberOfChairs: () => {
    const localization = useLocalization();
    return (
      <Form.Item
        name="numberOfChairs"
        label={localization.formatMessage(Locale.Attribute.Number_of_chairs)}
      >
        <Input
          autoComplete="newpassword"
          placeholder={localization.formatMessage(Locale.Command.Enter_number_of_chairs)}
        />
      </Form.Item>
    );
  },
  siteSectorType: () => {
    const localization = useLocalization();
    return (
      <Form.Item name='siteSectorType' label={localization.formatMessage(Locale.Attribute.Site_sector_type)}>
        <SiteSectorFormInput />
      </Form.Item>
    );
  },
  webPage: () => {
    const localization = useLocalization();
    return (
      <Form.Item
        name="webPage"
        label={localization.formatMessage(Locale.Attribute.Web_page)}
      >
        <Input
          autoComplete="newpassword"
          placeholder={localization.formatMessage(Locale.Command.Enter_Name)}
        />
      </Form.Item>
    );
  },
  contacts: ({ keyCodes = [] }) => {
    const localization = useLocalization();
    const formListInitialValue = keyCodes?.map(keyCode => ({ categoryCode: keyCode }));
    return (
      <Form.List name='contacts' initialValue={formListInitialValue}>
        {
          (fields) => fields?.map((field, index) => <Form.Item
            key={field.key}
            label={index === 0 ? localization.formatMessage(Locale.General.Contact_information): ' '}
            labelCol={{ span: 4 }}
            colon={index === 0}
            shouldUpdate={() => true}
          >
            {() => (<ContactFormInput
              namePath={[field.name.toString()]}
              entityType={EntityTypeEnum.SITE}
            />)}
          </Form.Item>)
        }
      </Form.List>
    );
  },
  customFieldValues: () => <span>contacts not supported</span>,
  chainCode: () => {
    const localization = useLocalization();
    return (
      <Form.Item noStyle shouldUpdate={(prev, curr) => prev.country !== curr.country}>
        {({ getFieldValue }) => (
          <Form.Item name="chainCode" label={localization.formatMessage(Locale.Attribute.Chain)}>
            <ValueChainsFormInput countryCode={getFieldValue('country')} />
          </Form.Item>
        )}
      </Form.Item>
    );
  },
  parentSiteId: () => {
    const localization = useLocalization();
    return (
      <Form.Item name='parentSiteId' label={localization.formatMessage(Locale.Attribute.Parent_site)}>
        <SiteAssignFormInput />
      </Form.Item>
    );
  }, // entity search popup (no create) use entitiesSearch's quickAction select, onClick use siteTableSelection to get value
  specialtyCodes: () => {
    const localization = useLocalization();
    const form = Form.useFormInstance();
    const countryCode = Form.useWatch('country', form);
    return (
      <Form.Item name='specialtyCodes' label={localization.formatMessage(Locale.Attribute.Site_specialty)}>
        <SiteSpecialityFormInput countryCode={countryCode} />
      </Form.Item>
    );
  },
};


const DepartmentInput: React.FC<{
  noDepartmentState: StateArray<boolean>;
  value?: string;
  onChange?: (e: ChangeEvent) => void;
}> = (props) => {
  const localization = useLocalization();
  return (
    <div style={{ display: 'flex', columnGap: 8, alignItems: 'center' }}>
      <Input
        id="departmentName"
        disabled={props.noDepartmentState[0]}
        placeholder={localization.formatMessage(Locale.Command.Enter_Department)}
        value={props.value}
        onChange={props.onChange}
      />
      <span style={{ whiteSpace: 'pre' }}>
        No department
      </span>
      <Checkbox
        id="noDepartment"
        checked={props.noDepartmentState[0]}
        onChange={e => props.noDepartmentState[1](e.target.checked)}
      />
    </div>
  );
};

export const TOTAL_ADDRESS_TYPE = gql`
  query TotalAddressTypeQuery($entityType: EntityTypeEnum) {
    addressTypes(criteria: { entityType: $entityType }) {
      hash
      totalCount
    }
  }
`;
