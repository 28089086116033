import React, { useCallback, useMemo, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useLocalization } from '../../../util/useLocalization';
import { Optional } from '../../../util/StateArrayType';
import { EntitiesSearchQuickActionItem } from '../../../components/entitiesSearch/top/EntitiesSearchQuickActionItemType';
import DcrModalConfirm from '../../../components/DcrModalConfirm/DcrModalConfirm';
import { Locale } from '../../../../localization/LocalizationKeys';
import {
  EntityTypeEnum,
  PersonSiteAffiliationsQueryQuery,
  SetNonPrimaryLinkMutationMutation,
  SetNonPrimaryLinkMutationMutationVariables,
  SetPrimaryLinkMutationMutation,
  SetPrimaryLinkMutationMutationVariables
} from '../../../../gql/typings';
import { useDetailsContext } from '../../../components/DetailsView/useDetailsContext';
import { useIsAnyInactive } from '../../../util/useIsAnyInactive';
import { TableRowSelectionReturnProps } from '../../../components/Table/useTableRowSelection';


type ReturnType = {
  action: EntitiesSearchQuickActionItem;
  hidden: React.ReactNode;
};

export const useSetPrimaryLinkAction = (
  data: Optional<PersonSiteAffiliationsQueryQuery>,
  tableSelection: TableRowSelectionReturnProps,
): ReturnType => {
  const { selected } = tableSelection.selection;
  const localization = useLocalization();
  const [modalVisible, setModalVisible] = useState(false);
  const [hasSelectedInactivateRecord] = useIsAnyInactive(EntityTypeEnum.AFFILIATION, selected);

  const [
    setIsPrimary,
    { loading: loading1 },
  ] = useMutation<SetPrimaryLinkMutationMutation, SetPrimaryLinkMutationMutationVariables>(SET_PRIMARY);
  const [
    setIsNonPrimary,
    { loading: loading2 },
  ] = useMutation<SetNonPrimaryLinkMutationMutation, SetNonPrimaryLinkMutationMutationVariables>(SET_NON_PRIMARY);

  const { broadcastShouldRefetchData } = useDetailsContext();

  const selectedIsPrimary = data?.person?.affiliations.nodes.filter(a => a.id === selected[0])[0]?.isPrimary;
  const willCreateDcr = data?.person?.maintainerSourceCode === 'APB';

  const onSubmit = useCallback(() => {
    setModalVisible(false);
    if (!data?.person) return;

    return (selectedIsPrimary
      ? setIsNonPrimary({
        variables: {
          personId: data.person.id,
          linkId: selected![0]!,
        },
      }) : setIsPrimary({
        variables: {
          personId: data.person.id,
          linkId: selected![0]!,
        },
      })).then(() => broadcastShouldRefetchData());
  }, [broadcastShouldRefetchData, data, selected, selectedIsPrimary, setIsNonPrimary, setIsPrimary]);


  return useMemo(() => ({
    action: {
      key: 'set-primary-link',
      loading: loading1 || loading2,
      hide: hasSelectedInactivateRecord || selected.length !== 1,
      label: selectedIsPrimary
        ? localization.formatMessage(Locale.Command.Set_as_non_primary)
        : localization.formatMessage(Locale.Command.Set_as_primary),
      type: 'default',
      onClick: () => willCreateDcr
        ? setModalVisible(true)
        : onSubmit(),
    },
    hidden: (
      <DcrModalConfirm
        visibleState={[modalVisible, setModalVisible]}
        onAccept={onSubmit}
        messageKey={selectedIsPrimary
          ? Locale.Text.Set_as_primary_dcr_validation
          : Locale.Text.Remove_primary_dcr_validation}
      />
    ),
  }), [
    hasSelectedInactivateRecord,
    loading1,
    loading2,
    localization,
    modalVisible,
    onSubmit,
    selected,
    selectedIsPrimary,
    willCreateDcr,
  ]);
};

const SET_PRIMARY = gql`
  mutation SetPrimaryLinkMutation($linkId: ID!, $personId: ID!) {
    setPrimaryLink(linkId: $linkId, personId: $personId) {
      id
      isPrimary
    }
  }
`;

const SET_NON_PRIMARY = gql`
  mutation SetNonPrimaryLinkMutation($linkId: ID!, $personId: ID!) {
    setNonPrimaryLink(linkId: $linkId, personId: $personId) {
      id
      isPrimary
    }
  }
`;
